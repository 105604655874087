import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import styled from "styled-components"
import { BackgroundProps } from "./IBackgroundProps"

const SaefongFishBackgroundSection: React.FC<BackgroundProps> = ({
  id,
  className,
  children,
  style,
}) => {
  const { desktop } = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "saefong/saefong-last-cast-logo-and-notext.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 619) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const imageData = desktop.childImageSharp.fluid
  return (
    <BackgroundImage
      Tag="section"
      id={id}
      className={className}
      fluid={imageData}
      backgroundColor={`#000000`}
      // Title get's passed to both container and noscriptImg.
      title="Saefong Work Background Image"
      // To "force" the classic fading in of every image (especially on
      // imageData change for fluid / fixed) by setting `soft` on `fadeIn`:
      fadeIn={`soft`}
      // To be able to use stacking context changing elements yourself,
      // set this to true to disable the "opacity hack":
      preserveStackingContext={true}
      // You can "safely" (look them up beforehand ; ) add other props:
      role="img"
      aria-label="Saefong Work Background Image"
      style={style}
    >
      {children}
    </BackgroundImage>
  )
}

const StyledSaefongFishBackgroundSection = styled(SaefongFishBackgroundSection)`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) no-repeat
    center center;
  background-size: cover;
`
export default StyledSaefongFishBackgroundSection
