import React from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import "../work-pages.scss"
import StyledSaefongFishBackgroundSection from "../../../components/backgroundImages/SaefongFishBackgroundSection"
import { useStaticQuery, graphql } from "gatsby"
import { FluidImage } from "../../../components/images/FluidImage"

const SekkeiWork = () => {
  const query = useStaticQuery(graphql`
    query {
      saefongLogo: file(
        relativePath: { eq: "saefong/saefong-official-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oneMoreCastFront: file(
        relativePath: { eq: "saefong/saefong-onemorecast-tshirt.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1661) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      oneMoreCastBack: file(
        relativePath: { eq: "saefong/saefong-onemorecast-tshirt-back.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1661) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lastCastSweater: file(
        relativePath: { eq: "saefong/saefong-lastcast-sweater.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1661) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Saefong Work" />

      <StyledSaefongFishBackgroundSection
        id="imageSpacer"
        style={{ position: "unset", backgroundAttachment: "fixed" }}
      />
      <div id="infoArea">
        <ul id="groupContainer">
          <li id="leftColumn">
            <FluidImage data={query.saefongLogo} alt="Saefong Logo" />
          </li>
          {/* middle column is just a white background div :P */}
          <li id="middleColumn"></li>
          <li id="rightColumn">
            MERCHANDISE
            <br />
            <div id="subText">Design and Branding</div>
          </li>
        </ul>
      </div>

      {/* 
        this is so that I can change the background color of 
        all the images w/out changing the background of the body 
      */}
      <div id="pictureContainer">
        {/* area for the pictures and stuff */}
        <section className="pictureSection">
          <FluidImage
            data={query.oneMoreCastFront}
            alt="Saefong One More Cast Front"
          />
        </section>
        <section className="pictureSection">
          <FluidImage
            data={query.oneMoreCastBack}
            alt="Saefong One More Cast Back"
          />
        </section>
        <section className="pictureSection">
          <FluidImage
            data={query.lastCastSweater}
            alt="Saefong Last Cast Sweater"
          />
        </section>
      </div>
    </Layout>
  )
}

export default SekkeiWork
